import { createSlice } from "@reduxjs/toolkit";
import { clear } from "../session/sessionSlice";

const initialState = {
  loading: false,
  cache: false,
  about: false,
  password: false,
  logout: false,
  settings: false,
  error: {
    show: false,
    message: undefined,
  },
  message: {
    show: false,
    message: undefined,
  },
  menu: false,
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      return { ...state, loading: action.payload };
    },
    setError: (state, action) => {
      state.error = {
        show: action.payload.show,
        message: action.payload.message,
      };
    },
    setMessage: (state, action) => {
      state.message = {
        show: action.payload.show,
        message: action.payload.message,
      };
    },
    setAbout: (state, action) => {
      return { ...state, about: action.payload };
    },
    setPasswordModal: (state, action) => {
      return { ...state, password: action.payload };
    },
    setLogOut: (state, action) => {
      return { ...state, logout: action.payload };
    },
    setCache: (state, action) => {
      return { ...state, cache: action.payload };
    },
    setSaveModal: (state, action) => {
      return { ...state, saveRecord: action.payload };
    },
    setSettingsModal: (state, action) => {
      return { ...state, settings: action.payload };
    },
    toogleMenu: (state) => {
      return { ...state, menu: !state.menu };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clear, () => initialState);
  },
});

export const {
  setLoading,
  setError,
  setMessage,
  setAbout,
  setPasswordModal,
  setLogOut,
  setCache,
  setSaveModal,
  setSettingsModal,
  toogleMenu,
} = modalsSlice.actions;

export default modalsSlice.reducer;
