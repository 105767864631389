import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "../ui/Spinner";

const PrivateRoute = React.lazy(() => import("./PrivateRoute"));
const PublicRoute = React.lazy(() => import("./PublicRoute"));

const Login = React.lazy(() => import("../routes/login/Login"));
const App = React.lazy(() => import("../App"));
const Accept = React.lazy(() => import("./agreements/accept/Accept"));
const Decline = React.lazy(() => import("./agreements/decline/Decline"));

const AppRoutes = () => {
  const appLoading = useSelector((state) => state.modals.loading);

  return (
    <React.Fragment>
      {appLoading && <Spinner />}
      <Router>
        <React.Suspense fallback={<Spinner />}>
          <Routes>
            <Route exact path="/" element={<PrivateRoute />}>
              <Route exact path="/" element={<App />} />
            </Route>
            <Route exact path="/login" element={<PublicRoute />}>
              <Route exact path="/login" element={<Login />} />
            </Route>
            <Route exact path="/agreements/accept" element={<Accept />} />
            <Route exact path="/agreements/decline" element={<Decline />} />
          </Routes>
        </React.Suspense>
      </Router>
    </React.Fragment>
  );
};

export default AppRoutes;
