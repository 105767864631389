import { combineReducers } from "redux";

import sessionReducer from "./features/session/sessionSlice";
import modalsReducer from "./features/modals/modalsSlice";

const rootReducer = combineReducers({
  session: sessionReducer,
  modals: modalsReducer,
});

export default rootReducer;
